import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SettingsSidebar from './SettingsSidebar';
import Header from './Header';
import './SettingsPage.css';

const SettingsPage = () => {
    const defaultSettings = {
        llmProvider: 'OpenAI',
        model: 'gpt-4o-mini',
        temperature: 0.5,
        maxTokens: 2500,
        url: 'https://api.openai.com/v1/chat/completions',
    };

    const loadSettingsFromLocalStorage = () => {
        const savedSettings = localStorage.getItem('llmSettings');
        return savedSettings ? JSON.parse(savedSettings) : defaultSettings;
    };

    const [settings, setSettings] = useState(loadSettingsFromLocalStorage());
    const [prompts, setPrompts] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [activeTab, setActiveTab] = useState('Main');
    const [newPromptName, setNewPromptName] = useState('');
    const [newPromptText, setNewPromptText] = useState('');

    const providerModelMap = {
        OpenAI: [
            { name: 'GPT-4o-mini', url: 'https://api.openai.com/v1/chat/completions' },
            { name: 'GPT-4', url: 'https://api.openai.com/v1/chat/completions' }
        ],
        Anthropic: [
            { name: 'claude-3-5-sonnet', url: 'https://api.anthropic.com/v1/messages' }
        ],
        OpenSource: [
            { name: 'deepseek-r1:32b', url: 'http://192.168.69.74:11434/api/generate' }
        ]
    };

    useEffect(() => {
        if (activeTab === 'Prompts') {
            axios.get('https://r3-pearl.ddns.net/api/prompts')
                .then(response => {
                    setPrompts(response.data);
                })
                .catch(error => console.error('Error fetching prompts:', error));
        }
    }, [activeTab]);

    useEffect(() => {
        localStorage.setItem('llmSettings', JSON.stringify(settings));
        const currentProviderModels = providerModelMap[settings.llmProvider];
        if (currentProviderModels) {
            const currentModel = currentProviderModels.find(
                model => model.name === settings.model
            ) || currentProviderModels[0];
            setSettings(prevSettings => ({
                ...prevSettings,
                model: currentModel.name,
                url: currentModel.url
            }));
        }
    }, [settings.llmProvider]);

    const handleSettingChange = (key, value) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            [key]: value
        }));
        console.log(`Updated settings: ${key} = ${value}`);
    };

    const handleSelectPrompt = (event) => {
        const promptId = event.target.value;
        const prompt = prompts.find(p => p.id.toString() === promptId);
        setSelectedPrompt(prompt || null);
        setNewPromptName(prompt ? prompt.prompt_name : '');
        setNewPromptText(prompt ? prompt.prompt_text : '');
    };

    const handleUpdatePrompt = () => {
        if (!selectedPrompt) {
            alert('Please select a prompt to update.');
            return;
        }

        axios.put(`https://r3-pearl.ddns.net/api/prompts/update/${selectedPrompt.id}`, {
            name: newPromptName,
            text: newPromptText,
        }).then(response => {
            const updatedPrompts = prompts.map(p =>
                p.id === response.data.id ? response.data : p
            );
            setPrompts(updatedPrompts);
        }).catch(error => console.error('Error updating prompt:', error));
    };

    const handleCreatePrompt = () => {
        axios.post('https://r3-pearl.ddns.net/api/prompts/create', {
            name: newPromptName,
            text: newPromptText,
        }).then(response => {
            setPrompts([...prompts, response.data]);
            setNewPromptName('');
            setNewPromptText('');
        }).catch(error => console.error('Error creating new prompt:', error));
    };

    return (
        <div>
            <Header />
            <div className="settings-page-container">
                <SettingsSidebar
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <div className="content">
                    <h2>Settings</h2>
                    <div className="tab-content">
                        {activeTab === 'Main' && (
                            <MainContent
                                settings={settings}
                                onSettingChange={handleSettingChange}
                                providerModelMap={providerModelMap}
                            />
                        )}
                        {activeTab === 'Prompts' && (
                            <div>
                                <h3>Select a System Prompt</h3>
                                <select onChange={handleSelectPrompt} value={selectedPrompt?.id || ''}>
                                    <option value="">Select a prompt</option>
                                    {prompts.map(prompt => (
                                        <option key={prompt.id} value={prompt.id}>
                                            {prompt.prompt_name}
                                        </option>
                                    ))}
                                </select>

                                <div style={{ marginTop: '20px' }} className="prompt-details">
                                    <h3>Prompt Details</h3>
                                    <label>
                                        Name:
                                        <input
                                            type="text"
                                            value={newPromptName}
                                            onChange={(e) => setNewPromptName(e.target.value)}
                                        />
                                    </label>
                                    <label>
                                        Text:
                                        <textarea
                                            value={newPromptText}
                                            onChange={(e) => setNewPromptText(e.target.value)}
                                        />
                                    </label>
                                    <div className="prompt-buttons">
                                        <button onClick={handleUpdatePrompt}>Update Prompt</button>
                                        <button onClick={handleCreatePrompt}>Save as New Prompt</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'Data Upload' && (
                            <FileUploadHandler />
                        )}
                        {activeTab === 'Other Settings' && <OtherSettingsContent />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const MainContent = ({ settings, onSettingChange, providerModelMap }) => (
    <div>
        <h3>Main Settings</h3>
        <p>Configure your main settings here.</p>
        <div>
            <label>
                Select LLM Provider:
                <select value={settings.llmProvider} onChange={(e) => onSettingChange('llmProvider', e.target.value)}>
                    {Object.keys(providerModelMap).map(provider => (
                        <option key={provider} value={provider}>{provider}</option>
                    ))}
                </select>
            </label>
        </div>
        <div>
            <label>
                Select Model:
                <select value={settings.model} onChange={(e) => onSettingChange('model', e.target.value)}>
                    {providerModelMap[settings.llmProvider].map(model => (
                        <option key={model.name} value={model.name}>{model.name}</option>
                    ))}
                </select>
            </label>
        </div>
        <div className="model-configuration">
            <h4>Model Configuration</h4>
            {Object.entries(settings).map(([key, value]) => key !== "llmProvider" && key !== "model" && (
                <div key={key}>
                    <label>
                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                        <input
                            type={typeof value === "number" ? "number" : "text"}
                            value={value}
                            onChange={(e) => onSettingChange(key, typeof value === "number" ? parseFloat(e.target.value) : e.target.value)}
                        />
                    </label>
                </div>
            ))}
        </div>
    </div>
);

const OtherSettingsContent = () => (
    <div>
        <h3>Other Settings</h3>
        <p>Configure other application settings here.</p>
    </div>
);

const FileUploadHandler = () => {
    const [files, setFiles] = useState([]);
    const [directoryName, setDirectoryName] = useState('');

    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const handleDirectoryChange = (event) => {
        setDirectoryName(event.target.value);
    };

    const handleFileUpload = async () => {
        if (files.length === 0 || !directoryName) {
            alert('Please enter a directory name and select files to upload.');
            return;
        }
        const formData = new FormData();
        formData.append('directory', directoryName);
        Array.from(files).forEach(file => formData.append('uploaded_files', file));

        try {
            const response = await fetch('https://r3-pearl.ddns.net/api/upload-and-save', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error('File upload error:', error);
        }
    };

    return (
        <div>
            <h3>Upload Files</h3>
            <input
                type="text"
                placeholder="Enter directory name"
                value={directoryName}
                onChange={handleDirectoryChange}
            />
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload</button>
        </div>
    );
};

export default SettingsPage;
