// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import ChatWindow from './components/ChatWindow';
// import ChatHistorySidebar from './components/ChatHistorySidebar';
// import Header from './components/Header';
// import SettingsPage from './components/SettingsPage'; // Updated import from SettingsModal to SettingsPage
// import DataView from './components/DataView';
// import DataReview from './components/DataReview';
// import Home from './components/Home';
// import Login from './components/Login';

// function App() {
//     const [selectedChat, setSelectedChat] = useState(null);
//     const [systemPrompt, setSystemPrompt] = useState('');
//     const [isLoggedIn, setIsLoggedIn] = useState(false);

//     useEffect(() => {
//         const loginState = localStorage.getItem('isLoggedIn');
//         setIsLoggedIn(!!loginState);
//         console.log(`Login state set: ${loginState}`);
//     }, []);

//     const handleSelectChat = (chat) => {
//         setSelectedChat(chat);
//     };

//     const handleSelectPrompt = (promptText) => {
//         setSystemPrompt(promptText);
//     };

//     console.log(`Rendering App - Is Logged In: ${isLoggedIn}`);

//     return (
//         <Router>
//             {isLoggedIn && <Header />}
//             <div className="main-content">
//                 <Switch>
//                     <Route exact path="/">
//                         {isLoggedIn ? <Redirect to="/home" /> : <Login />}
//                     </Route>
//                     <Route path="/home">
//                         {isLoggedIn ? <Home /> : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/chat">
//                         {isLoggedIn ? (
//                             <>
//                                 <ChatHistorySidebar onSelectChat={handleSelectChat} />
//                                 <ChatWindow selectedChat={selectedChat} systemPrompt={systemPrompt} />
//                             </>
//                         ) : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/dataview">
//                         {isLoggedIn ? <DataView /> : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/datareview">
//                         {isLoggedIn ? <DataReview /> : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/settings">
//                         {isLoggedIn ? <SettingsPage onSelectPrompt={handleSelectPrompt} /> : <Redirect to="/" />}
//                     </Route>
//                 </Switch>
//             </div>
//         </Router>
//     );
// }

// export default App;

// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
// import ChatWindow from './components/ChatWindow';
// import ChatHistorySidebar from './components/ChatHistorySidebar';
// import Header from './components/Header';
// import SettingsPage from './components/SettingsPage'; // Updated import from SettingsModal to SettingsPage
// import DataView from './components/DataView';
// import DataReview from './components/DataReview';
// import Home from './components/Home';
// import Login from './components/Login';

// function App() {
//     const [selectedChat, setSelectedChat] = useState(null);
//     const [systemPrompt, setSystemPrompt] = useState('');
//     const [isLoggedIn, setIsLoggedIn] = useState(false);
//     const history = useHistory();

//     useEffect(() => {
//         const loginState = localStorage.getItem('isLoggedIn');
//         setIsLoggedIn(!!loginState);
//         console.log(`Login state set: ${loginState}`);
//     }, []);

//     const handleSelectChat = (chat) => {
//         setSelectedChat(chat);
//     };

//     const handleSelectPrompt = (promptText) => {
//         setSystemPrompt(promptText);
//     };

//     const handleNavigation = (path) => {
//         setSelectedChat(null); // Clear selectedChat when navigating away
//         history.push(path);
//     };

//     console.log(`Rendering App - Is Logged In: ${isLoggedIn}`);

//     return (
//         <Router>
//             {isLoggedIn && <Header />}
//             <div className="main-content">
//                 <Switch>
//                     <Route exact path="/">
//                         {isLoggedIn ? <Redirect to="/home" /> : <Login />}
//                     </Route>
//                     <Route path="/home">
//                         {isLoggedIn ? <Home /> : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/chat">
//                         {isLoggedIn ? (
//                             <>
//                                 <ChatHistorySidebar onSelectChat={handleSelectChat} />
//                                 <ChatWindow selectedChat={selectedChat} systemPrompt={systemPrompt} setSelectedChat={setSelectedChat} />
//                             </>
//                         ) : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/dataview">
//                         {isLoggedIn ? <DataView /> : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/datareview">
//                         {isLoggedIn ? <DataReview /> : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/settings">
//                         {isLoggedIn ? <SettingsPage onSelectPrompt={handleSelectPrompt} /> : <Redirect to="/" />}
//                     </Route>
//                 </Switch>
//             </div>
//         </Router>
//     );
// }

// export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import ChatWindow from './components/ChatWindow';
import ChatHistorySidebar from './components/ChatHistorySidebar';
import Header from './components/Header';
import SettingsPage from './components/SettingsPage';
import DataView from './components/DataView';
import DataReview from './components/DataReview';
import Home from './components/Home';
import Login from './components/Login';

function App() {
    const [selectedChat, setSelectedChat] = useState(null);
    const [chatHistory, setChatHistory] = useState([]); // Manage chat history at top level
    const [systemPrompt, setSystemPrompt] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const loginState = localStorage.getItem('isLoggedIn');
        setIsLoggedIn(!!loginState);
        console.log(`Login state set: ${loginState}`);
    }, []);

    const handleSelectChat = (chat) => {
        setSelectedChat(chat);
    };

    const handleSelectPrompt = (promptText) => {
        setSystemPrompt(promptText);
    };

    const updateChatHistory = (newHistory) => {
        setChatHistory(newHistory); // Update chat history
    };

    console.log(`Rendering App - Is Logged In: ${isLoggedIn}`);

    return (
        <Router>
            {isLoggedIn && <Header />}
            <div className="main-content">
                <Switch>
                    <Route exact path="/">
                        {isLoggedIn ? <Redirect to="/home" /> : <Login />}
                    </Route>
                    <Route path="/home">
                        {isLoggedIn ? <Home /> : <Redirect to="/" />}
                    </Route>
                    <Route path="/chat">
                        {isLoggedIn ? (
                            <>
                                <ChatHistorySidebar
                                    onSelectChat={handleSelectChat}
                                    chatHistory={chatHistory}
                                />
                                <ChatWindow
                                    selectedChat={selectedChat}
                                    setSelectedChat={setSelectedChat}
                                    systemPrompt={systemPrompt}
                                    updateChatHistory={updateChatHistory} // Pass callback to ChatWindow
                                />
                            </>
                        ) : <Redirect to="/" />}
                    </Route>
                    <Route path="/dataview">
                        {isLoggedIn ? <DataView /> : <Redirect to="/" />}
                    </Route>
                    <Route path="/datareview">
                        {isLoggedIn ? <DataReview /> : <Redirect to="/" />}
                    </Route>
                    <Route path="/settings">
                        {isLoggedIn ? <SettingsPage onSelectPrompt={handleSelectPrompt} /> : <Redirect to="/" />}
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;

// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
// import ChatWindow from './components/ChatWindow';
// import ChatHistorySidebar from './components/ChatHistorySidebar';
// import Header from './components/Header';
// import SettingsPage from './components/SettingsPage';
// import DataView from './components/DataView';
// import DataReview from './components/DataReview';
// import Home from './components/Home';
// import Login from './components/Login';

// function App() {
//     const [selectedChat, setSelectedChat] = useState(null);
//     const [chatHistory, setChatHistory] = useState([]);
//     const [systemPrompt, setSystemPrompt] = useState('');
//     const [isLoggedIn, setIsLoggedIn] = useState(false);
//     const [fetchError, setFetchError] = useState('');
//     const history = useHistory();

//     useEffect(() => {
//         const loginState = localStorage.getItem('isLoggedIn');
//         setIsLoggedIn(!!loginState);
//         console.log(`Login state set: ${loginState}`);
//     }, []);

//     const handleSelectChat = (chat) => {
//         setSelectedChat(chat);
//     };

//     const handleSelectPrompt = (promptText) => {
//         setSystemPrompt(promptText);
//     };

//     const updateChatHistory = async () => {
//         try {
//             const username = localStorage.getItem('username');
//             if (!username) return;
//             const response = await axios.get(`https://r3-pearl.ddns.net/api/chat-history?username=${username}`);
//             setChatHistory(response.data);
//         } catch (err) {
//             setFetchError('Failed to fetch chat history. Please try again.');
//         }
//     };

//     useEffect(() => {
//         updateChatHistory();
//     }, [isLoggedIn]);

//     return (
//         <Router>
//             {isLoggedIn && <Header />}
//             <div className="main-content">
//                 <Switch>
//                     <Route exact path="/">
//                         {isLoggedIn ? <Redirect to="/home" /> : <Login />}
//                     </Route>
//                     <Route path="/home">
//                         {isLoggedIn ? <Home /> : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/chat">
//                         {isLoggedIn ? (
//                             <>
//                                 <ChatHistorySidebar
//                                     onSelectChat={handleSelectChat}
//                                     chatHistory={chatHistory}
//                                     error={fetchError}
//                                 />
//                                 <ChatWindow
//                                     selectedChat={selectedChat}
//                                     setSelectedChat={setSelectedChat}
//                                     updateChatHistory={updateChatHistory}
//                                     systemPrompt={systemPrompt}
//                                 />
//                             </>
//                         ) : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/dataview">
//                         {isLoggedIn ? <DataView /> : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/datareview">
//                         {isLoggedIn ? <DataReview /> : <Redirect to="/" />}
//                     </Route>
//                     <Route path="/settings">
//                         {isLoggedIn ? <SettingsPage onSelectPrompt={handleSelectPrompt} /> : <Redirect to="/" />}
//                     </Route>
//                 </Switch>
//             </div>
//         </Router>
//     );
// }

// export default App;
